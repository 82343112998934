<template>
    <div
        class="max-w-screen-md mx-auto bg-base text-base safe-h-screen select-none relative"
    >
        <div class="w-full h-screen flex justify-center items-center" v-if="orderStore.isLoading">
            <img
                v-if="restIsLoad && restHasLoaderLogo"
                :src="orderStore.rest.white_label.loader_logo"
                alt="загрузчик"
                class="w-full h-screen absolute"
            >
            <AnimatedLogo
                v-else-if="restIsLoad && !restHasLoaderLogo"
                class="max-w-xs px-10"
            />
        </div>
        <transition name="fade">
            <div class="bg-inherit" :class="!orderStore.isLoading ? 'safe-h-screen' : ''">
                <slot/>
                <transition name="fade">
                    <div
                        v-if="orderStore.isPaySending"
                        class="fixed top-0 left-0 w-screen h-screen bg-white/50 backdrop-blur-sm z-50 flex justify-center items-center">
                        <svg class="animate-spin h-20 w-20" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                </transition>
                <transition name="fade">
                    <div
                        v-if="orderStore.isLocked && !orderStore.isPaid"
                        class="fixed top-0 left-0 w-screen h-screen bg-white/50 z-30 text-center"
                    >
                        <div class="py-6 bg-white text-sm font-bold">
                            <span class="animate-pulse">Заказ оплачивается</span>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>
<script lang="ts" setup>
import { useOrderStore } from "@/stores/order"
import AnimatedLogo from "@/components/AnimatedLogo.vue"
import { computed, onMounted } from "vue"


defineProps({
    isSending: Boolean,
    isLoading: Boolean
})

const orderStore = useOrderStore()

const restIsLoad = computed((): boolean => {
    return Object.keys(orderStore.rest).length > 0
})

const restHasLoaderLogo = computed((): boolean => {
    return !!orderStore.rest.white_label?.loader_logo
})

onMounted(async () => {
    await orderStore.loadGlobalSettings()
})
</script>
