import { defineStore } from "pinia"
import orderService from "@/services/orderService"
import storage from "@/helpers/storage"
import { v4 as uuidv4 } from "uuid"
import { io } from "socket.io-client"
import router from "@/router"
import styleService from "@/services/styleService"
import tipsService from "@/services/tipsService"
import restService from "@/services/restService"
import Restaurant from "@/types/Restaurant/Restaurant"
import LogicError from "@/errors/LogicError"
import Order from "@/types/Order/Order"
import { AxiosError } from "axios"
import RestaurantLink from "@/types/Restaurant/RestaurantLink"
import StoreProduct from "@/types/StoreProduct"
import BankSbp from "@/types/BankSbp"
import PaymentType from "@/types/PaymentType"
import Payment from "@/types/Payment/Payment"
import SelectedProduct from "@/types/SelectedProduct"
import Bonus from "@/types/Socket/Bonus"
import RestaurantAdditionalParams from "@/types/Order/RestaurantAdditionalParams"
import BonusStatus from "@/types/BonusStatus"
import TipsEmployee from "@/types/Order/TipsEmployee"
import OrderWaiter from "@/types/Order/OrderWaiter"
import OrderWithTable from "@/types/Order/OrderWithTable"
import settingsService from "@/services/settingsService"
import Setting from "@/types/Settings/Setting"

type SocketOrder = {
    product_id: string
    users: Record<string, number>
}

const ORDER_INIT_STATE = 'init'
const ORDER_LOAD_STATE = 'load'
const ORDER_EMPTY_STATE = 'empty'
const ORDER_ERROR_STATE = 'error'
const ORDER_SPLIT_STATE = 'split'
const ORDER_PAID_STATE = 'paid'
const ORDER_FINISHED_STATE = 'finished'

const userId = storage.get('user_id') ? storage.get('user_id') : storage.set('user_id', uuidv4())
const storedPaymentType: PaymentType = storage.get('payment_type') ? storage.get('payment_type') : 'sbp'
const storedUserEmail: Nullable<string> = storage.get('user_email') ? storage.get('user_email') : null
const socket = io()

export const useOrderStore = defineStore('orderStore', {
    state: () => {
        return {
            hasUserInputPaymentData: false, // ставивтся true, если пользователь попал на страницу платежа без перезагрузок страницы
            userId: userId,
            tableSlug: null as Nullable<string>,
            tableCode: null as Nullable<string>,
            restSlug: null as Nullable<string>,
            rest: {} as Restaurant,
            orderState: ORDER_INIT_STATE as string,
            orderData: null as Nullable<Order>,
            waiter: null as Nullable<OrderWaiter>,
            tipsEmployee: null as Nullable<TipsEmployee>,
            splitState: null,
            isHome: true,
            isLocked: false,
            isPayCommission: true,
            isFixedCommission: false,
            isTipsMessageShow: false,
            isTermsConfirm: true,
            commissionMessage: null as Nullable<string>,
            tipsType: null as Nullable<string>,
            tipsSteps: [] as number[],
            tipsSumSteps: [] as number[],
            tipsStepsType: 'percent' as 'percent' | 'sum',
            tipsDefault: null as Nullable<number>,
            changeTips: false, // пользователь изменил дефолтные чаевые
            tipsUnselected: false, // чаевые не выбраны
            tipsEmoji: false,
            isPaySending: false,
            isOrderPay: true,
            isOnlyTipsPay: false,
            isLoading: true,
            orderId: null as Nullable<string>,
            products: [] as StoreProduct[],
            totalSum: 0,
            paidSum: 0,
            prepaySum: 0,
            tipsOrderSum: 0,
            tipsCustomSum: 0,
            tipsPercent: 0, // выбранный процент чаевых
            tipsAbsoluteSum: 0, // выбранная абсолютная сумма чаевых
            orderCommissionPercent: 0,
            orderCommissionSum: 0,
            tipsCommissionPercent: 0,
            tipsCommissionSum: 0,
            splitCount: 2,
            lockedSum: 0,
            errorMessage: null as Nullable<string>,
            isRestNetworkError: false,
            isBack: false,
            links: [] as RestaurantLink[],
            banks: [] as BankSbp[],
            isShowPaymentTypeSelect: true,
            paymentType: storedPaymentType,
            paymentTypeList: {
                sbp: 'Система быстрых платежей',
                card: 'Банковская карта'
            } as const,
            availablePaymentTypes: ['sbp', 'card'] as PaymentType[], // доступные для выбора способы оплаты
            emailInput: null as Nullable<HTMLInputElement>,
            emailValid: true,
            userEmail: storedUserEmail,
            checkPaymentTimer: undefined as number | undefined,
            payment: null as Nullable<Payment>,
            sbpVariantsShow: false,
            paymentTypeSelectShow: false,
            bankPaymentLink: null as Nullable<string>,
            bonusSum: 0,
            bonusStatus: null as Nullable<BonusStatus>,
            bonusEvent: null as Nullable<Bonus>,
            fiscalType: null as Nullable<string>,
            additionalParams: null as Nullable<RestaurantAdditionalParams>,
            comment: '',
            rating: 0,
            globalSettings: {} as Setting[],
            need_age_verification: null as Nullable<boolean>,
            age_verification_state: null as Nullable<boolean>,
            age_verification_notification_sent_at: null as Nullable<string>,
            prePayment: false,
            postPayment: false,
            canAddItems: true,
            waiterCreatingOrder: false,
            qrPayment: false,
            routerBack: null as Nullable<object>,
            isDemo: false,
            restOrderComment: null as Nullable<string>,
            restSmartCheckboxPositionCount: null as Nullable<number>,
            restSmartCheckboxSum: null as Nullable<number>
        }
    },
    getters: {
        getAvailablePaymentTypeList(state) {
            return Object.fromEntries(Object.entries(state.paymentTypeList).filter(([key]) => state.availablePaymentTypes.includes(key as PaymentType)))
        },
        isAvailablePaymentListEmpty(): boolean {
            return Object.keys(this.getAvailablePaymentTypeList).length === 0
        },
        isOrderLoad(state): boolean {
            return state.orderState !== ORDER_INIT_STATE
        },
        isOrderEmpty(state): boolean {
            return state.orderState === ORDER_EMPTY_STATE
        },
        isOrderError(state): boolean {
            return state.orderState === ORDER_ERROR_STATE
        },
        isSplit(state): boolean {
            return state.orderState === ORDER_SPLIT_STATE
        },
        isPaid(state): boolean {
            return state.orderState === ORDER_PAID_STATE
        },
        isOrderWithoutProducts(state): boolean {
            return state.products.length === 0
        },
        isSplitPaymentActive(state): boolean {
            return state.rest.split_payment_active
        },
        isFinished(state) {
            return state.orderState === ORDER_FINISHED_STATE
        },
        getSelectedProducts(state) {
            return state.products.filter(product => (product['selected']))
        },
        getPaidProductsSum(state) {
            let paidSum = 0
            state.products.map(product => {
                if (product['paidSum'] > 0) {
                    paidSum += product['paidSum']
                }
            })
            return paidSum
        },
        isKleekTips(state): boolean {
            return state.tipsType === 'kleek'
        },
        orderSum(state) {
            let orderSum = 0

            if (this.products.length > 0) {
                this.products.map(product => {
                    if (this.isSplit) {
                        orderSum += product['price'] * product['selected']
                    } else {
                        orderSum += product['price'] * product['availableAmount']
                    }
                    product.modifiers.map(modifier => {
                        if (this.isSplit) {
                            orderSum += modifier['price'] * modifier['amount'] * product['selected']
                        } else {
                            orderSum += modifier['price'] * modifier['amount'] * product['availableAmount']
                        }

                    })
                })
            } else if (state.orderData) {
                orderSum = state.orderData['sum'] / 100
            } else if (state.isOnlyTipsPay) {
                orderSum = state.tipsOrderSum
            }

            return Math.round(orderSum * 100) / 100 // округляем возможные дробные копейки
        },
        tipsSum(state): number {
            if (state.tipsStepsType === 'percent') {
                if (state.tipsPercent === 0) {
                    return state.tipsCustomSum
                } else {
                    return Math.ceil(this.orderSum * state.tipsPercent / 100)
                }
            }
            if (state.tipsStepsType === 'sum') {
                if (state.tipsAbsoluteSum === 0) {
                    return state.tipsCustomSum
                } else {
                    return state.tipsAbsoluteSum
                }
            }
            throw new Error('Неизвестный тип шагов')
        },
        totalCommissionSum(state): number {
            return state.orderCommissionSum + state.tipsCommissionSum
        },
        paymentSum(state): number {
            const orderSum = this.isOrderPay ? this.orderSum - this.prepaySum : 0
            const tipsSum = this.tipsSum
            let paymentSum = orderSum

            if (this.isOnlyTipsPay) {
                paymentSum = tipsSum
            } else {
                paymentSum = paymentSum + tipsSum
                state.orderCommissionSum = Math.ceil(orderSum * state.orderCommissionPercent / 100)
            }

            state.tipsCommissionSum = Math.ceil(tipsSum * state.tipsCommissionPercent / 100)

            if (state.isPayCommission) {
                paymentSum = paymentSum + state.orderCommissionSum + state.tipsCommissionSum
            }
            return Number(paymentSum.toFixed(2))
        },
        getLockAmountByIndex(state) {
            return (index: number) => {
                const locks = state.products[index]['lockedAmounts']
                let amount = 0
                for (const userId in locks) {
                    if (userId !== state.userId) {
                        amount += locks[userId]
                    }
                }
                return amount
            }
        },
        menuLinks(state) {
            return state.links.filter(el => !(/^#payment\|/.test(el.link)))
        },
        paymentLinks(state) {
            return state.links.filter(el => /^#payment\|/.test(el.link))
                .map(el => ({
                    ...el,
                    link: el.link.replace(/^#payment\|(.+)/, '$1')
                }))
        },
        getLockedProductsSum() {
            return false // TODO используется в шаблоне PaymentTotal, но не существует
        },
        bonusMask(state) {
            return state.additionalParams && state.additionalParams.bonusMask ? state.additionalParams.bonusMask : '0000'
        },
        bonusPlaceholder(state) {
            return state.additionalParams && state.additionalParams.bonusPlaceholder ? state.additionalParams.bonusPlaceholder : 'Введите карту'
        },
        bonusUseSms(state) {
            return state.additionalParams && state.additionalParams.bonusUseSms
        },
        bonusWriteOnSms(state) {
            const bonusWriteOnSms = typeof state.additionalParams?.bonusWriteOnSms !== 'undefined'
                ? state.additionalParams.bonusWriteOnSms
                : false

            return state.additionalParams && bonusWriteOnSms
        },
        bonusLockWriteOff(state) {
            return state.additionalParams && state.additionalParams.bonusLockWriteOff
        },
        bonusAddWriteOn(state) {
            return state.additionalParams && state.additionalParams.bonusAddWriteOn
        },
        bonusNeedAuth(state) {
            const bonusNeedAuth = typeof state.additionalParams?.bonusNeedAuth !== 'undefined'
                ? state.additionalParams.bonusNeedAuth
                : true

            return state.additionalParams && bonusNeedAuth
        },
        smartCheckBoxCommission(state) {
            if (state.restSmartCheckboxPositionCount === null || state.restSmartCheckboxSum === null || !state.isOrderPay) {
               return state.isPayCommission
            }

            let productCount = 0
            let orderSum = 0
            this.products.map(product => {
                if (this.isSplit)
                {
                    if (product['selected']) {
                        productCount++
                    }
                    orderSum += product['price'] * product['selected']
                } else {
                    if (product['availableAmount']) {
                        productCount++
                    }
                    orderSum += product['price'] * product['availableAmount']
                }
            })

            return  productCount >= state.restSmartCheckboxPositionCount && orderSum >= state.restSmartCheckboxSum
        }
    },
    actions: {
        updateRouterBack(routerData: Nullable<object>) {
            this.routerBack = routerData
        },
        async loadGlobalSettings() {
            this.globalSettings = await settingsService.getSettings()
            const paymentMethodsSettingData = this.globalSettings.find((setting) => setting.name === 'payment-methods')?.value
            if (paymentMethodsSettingData) {
                const availablePaymentMethods: PaymentType[] = []
                for (const paymentMethod in paymentMethodsSettingData) {
                    if (true === paymentMethodsSettingData[paymentMethod]) {
                        availablePaymentMethods.push(paymentMethod as PaymentType)
                    }
                }
                this.availablePaymentTypes = availablePaymentMethods
                if (availablePaymentMethods.includes(this.paymentType)) {
                    this.setPaymentType(this.paymentType)
                } else {
                    if (availablePaymentMethods.length > 0) {
                        this.setPaymentType(availablePaymentMethods[0])
                    }
                }
            }
        },
        setRest(rest: Restaurant) {
            this.restSlug = rest['slug']
            this.links = rest['links']
            this.rest = rest
            this.tipsType = rest['tips_type']
            this.prePayment = rest['pre_payment']
            this.postPayment = rest['post_payment']
            this.qrPayment = rest['qr_payment']
            this.waiterCreatingOrder = rest['waiter_creating_order']
            if (this.rest['custom_theme'] && this.rest['white_label']) {
                styleService.setStyle(rest['white_label'])
            }
            this.commissionMessage = rest['commission_message']
            this.isDemo = rest['demo']
        },
        async loadOrderBySlug(slug: string, preCheckId?: string) {
            this.errorMessage = null
            this.isRestNetworkError = false
            this.tableSlug = slug
            let orderData: Nullable<OrderWithTable> = null
            try {
                orderData = await orderService.getOrderByTableSlug(slug, preCheckId)

            } catch (error) {
                console.error(error)
                if (error instanceof AxiosError && error.response) {
                    if (error.response.status === 404) {
                        this.errorMessage = 'Стол не найден'
                    } else if (error.response.status === 503) {
                        this.isRestNetworkError = true
                        this.errorMessage = 'Касса недоступна'
                    } else {
                        this.errorMessage = error.response.data['error']
                    }
                    this.orderState = ORDER_ERROR_STATE
                    return
                }
                throw error
            }

             this.tableCode = orderData['table']['code']
             await this.setOrder(orderData['order'])
            if (orderData['order'] && orderData['order']['id']) {
                this.orderId = orderData['order']['id']
            } else {
                this.orderId = null
            }
        },
        async loadOrderById(orderId: string) {
            let orderData: Nullable<Order> = null
            try {
                orderData = await orderService.getOrder(orderId)
            } catch (error) {
                if (error instanceof AxiosError && error.response && error.response.status === 404) {
                    this.errorMessage = 'Заказ не найден'
                } else if (error instanceof Error) {
                    this.errorMessage = error.message
                }
                this.orderState = ORDER_ERROR_STATE
                return
            }

            if (!orderData['id']) {
                return false
                // const route = router.currentRoute.value
                // await router.push({
                //     name: 'not_found',
                //     params: { pathMatch: route.path.substring(1).split('/') },
                //     query: route.query,
                //     hash: route.hash,
                // })
            } else {
                await this.setOrder(orderData)
                return true
            }
        },
        async loadDemoOrderByRestaurant(restaurantId: string) {
            let orderData: Nullable<Order> = null
            try {
                orderData = await orderService.getDemoOrderByRestaurantId(restaurantId)
            } catch (error) {
                if (error instanceof AxiosError && error.response && error.response.status === 404) {
                    this.errorMessage = 'Заказ не найден'
                } else if (error instanceof Error) {
                    this.errorMessage = error.message
                }
                this.orderState = ORDER_ERROR_STATE
                return
            }

            if (!orderData['id']) {
                return false
            } else {
                await this.setOrder(orderData)
                return true
            }
        },
        async setOrder(order: Order) {

            const orderData = order
            if (!orderData || !orderData['id']) {
                this.orderState = ORDER_EMPTY_STATE
                return
            }
            this.userId = `${orderData['table_slug']}_${userId}`

            this.orderData = orderData
            this.orderId = orderData['id']
            this.tableSlug = orderData['table_slug']
            this.bonusSum = orderData['bonus_sum'] / 100
            this.bonusStatus = orderData['bonus_status']
            if (orderData.restaurant) {
                this.additionalParams = orderData.restaurant['additional_params']
                this.fiscalType = orderData.restaurant.fiscal_type
            }

            if (!this.restSlug) {
                const restData = await restService.getRestBySlug(orderData['restaurant']['slug'])
                this.setRest(restData)
            }

            if (order['tips_employee']) {
                this.tipsEmployee = order['tips_employee']
            } else if (order['waiter'] && order['waiter']['tips_employee']) {
                this.waiter = orderData['waiter']
                this.tipsEmployee = order['waiter']['tips_employee']
            }

            if (orderData['comment']) {
                this.restOrderComment = orderData['comment']
            }

            if (orderData['paid_sum'] >= orderData['sum'] - orderData['prepay_sum']) {
                this.orderState = ORDER_PAID_STATE
                if (orderData['product_type'] === 'qr') {
                    setTimeout(() => {
                        router.push({
                            name: 'order',
                            params: { rest_slug: orderData['restaurant']['slug'], order_id: orderData['id'] },
                        })
                    }, 0)
                }
                return
            }

            if (orderData['status'] === 'finished') {
                this.orderState = ORDER_FINISHED_STATE
                return
            }

            this.need_age_verification = orderData['need_age_verification']
            this.age_verification_state = orderData['age_verification_state']
            this.age_verification_notification_sent_at = orderData['age_verification_notification_sent_at']
            this.canAddItems = orderData['can_add_items']

            this.orderCommissionPercent = orderData['restaurant']['merchant_commission']
            this.tipsCommissionPercent = orderData['restaurant']['tips_commission']
            this.isFixedCommission = orderData['restaurant']['commission_fixed']
            if (orderData['restaurant']["smart_checkbox_position_count"] && orderData['restaurant']['smart_checkbox_sum']) {
                this.restSmartCheckboxPositionCount = orderData['restaurant']["smart_checkbox_position_count"]
                this.restSmartCheckboxSum = orderData['restaurant']["smart_checkbox_sum"]
            }
            this.isPayCommission = orderData['restaurant']['commission_checked']
            this.isTipsMessageShow = orderData['restaurant']['tips_message_show']
            this.commissionMessage = orderData['restaurant']['commission_message']
            this.tipsType = orderData['restaurant']['tips_type']

            if (orderData['restaurant']['tips_steps']) {
                this.tipsSteps = orderData['restaurant']['tips_steps']
                if (orderData['restaurant']['tips_default_step'] && orderData['restaurant']['tips_default_step'] > 0) {
                    this.tipsDefault = orderData['restaurant']['tips_default_step'] - 1
                } else {
                    this.tipsDefault = null
                }

                this.tipsSteps.push(0)
            }

            if (orderData['restaurant']['tips_emoji']) {
                this.tipsEmoji = orderData['restaurant']['tips_emoji']
            }

            this.totalSum = orderData['sum'] / 100
            this.paidSum = orderData['paid_sum'] / 100
            this.prepaySum = orderData['prepay_sum'] / 100

            const prevSelectedProducts = this.products.filter(prod => prod['selected'] > 0)

            this.products = orderData['products'].map(item => {
                const product = {
                    id: item['id'],
                    name: item['name'],
                    price: item['price'] / 100,
                    amount: item['amount'] / 100,
                    cost: item['cost'] / 100,
                    selected: 0,
                    paidSum: item['paid_sum'] / 100,
                    paidAmount: item['paid_amount'] / 100,
                    paymentSum: item['cost'] / 100 - item['paid_sum'] / 100,
                    availableAmount: (item['amount'] / 100) - (item['paid_amount'] / 100),
                    lockedAmounts: {},
                    modifiers: item['modifiers'] ? item['modifiers'].map(itemModifier => {
                        return {
                            name: itemModifier.name,
                            price: itemModifier.price / 100,
                            amount: itemModifier.amount / 100
                        }
                    }) : []
                }

                if (prevSelectedProducts.length > 0) {
                    const prevProduct = prevSelectedProducts.find(prod => prod['id'] === product['id'])
                    if (prevProduct) {
                        if (prevProduct['selected'] > 0 && prevProduct['selected'] <= product['availableAmount']) {
                            product['selected'] = prevProduct['selected']
                        }
                    }
                }

                return product
            })
            if (this.orderState === ORDER_INIT_STATE) {
                this.orderState = ORDER_LOAD_STATE
            }

            this.socketInit(orderData)

        },
        storePrePayOrderId() {
            storage.set('order_id_prepayment', this.orderId)
        },
        socketInit(orderData: Order) {
            socket.emit('join-order', orderData['id'], this.userId)

            socket.on('connect', () => {
                if (orderData['id']) {
                    socket.emit('join-order', orderData['id'], this.userId)
                }
            })

            socket.on('order', (order) => {
                this.lockOrder(order)
            })

            socket.on('product-lock-amount', (params) => {
                this.lockProductAvailableAmount(params['product_id'], params['users'])
            })

            socket.on('order.payment', async (payment) => {
                clearTimeout(this.checkPaymentTimer)
                await this.loadOrderById(payment['order_id'])
            })

            socket.on('order.full_payment', async (payment) => {
                clearTimeout(this.checkPaymentTimer)
                await this.loadOrderById(payment['order_id'])
            })

            socket.on('bonus.get', async (bonusParams: Bonus) => {
                this.bonusEvent = bonusParams
            })

        },
        checkLockedBonus() {
            socket.emit('bonus.get', {
                order_id: this.orderId
            })
        },
        lockBonus(success: boolean) {
            socket.emit('bonus.set', {
                order_id: this.orderId,
                success
            })
        },
        lockOrder(order: SocketOrder[]) {
            order.forEach(params => {
                this.lockProductAvailableAmount(params['product_id'], params['users'])
            })
        },
        lockProductAvailableAmount(productId: string, users: Record<string, number>) {
            this.products
                .filter(product => product['id'] === productId)
                .forEach(product => {
                    product['availableAmount'] = product['amount'] - product['paidAmount']
                    product['lockedAmounts'] = {}
                    let selected = 0
                    for (const lockUserId in users) {
                        if (lockUserId !== this.userId) {
                            selected += users[lockUserId]
                            product['lockedAmounts'][lockUserId] = users[lockUserId]
                        }
                    }
                    product['availableAmount'] = product['amount'] - product['paidAmount'] - selected

                    if (product['selected'] > 0 && product['selected'] > product['availableAmount']) {
                        product['selected'] = 0
                    }
                })
        },
        toggleSplit() {
            if (this.orderState === ORDER_SPLIT_STATE) {
                this.orderState = ORDER_LOAD_STATE
                const checkProductCount = this.getSelectedProducts.length
                if (checkProductCount) {
                    this.products
                        .filter((product) => product['selected'] > 0)
                        .forEach(product => { product['selected'] = 0 })
                    socket.emit('product-remove', {
                        order_id: this.orderId,
                    })
                }
            } else {
                this.orderState = ORDER_SPLIT_STATE
            }
        },
        toggleOrderPayment() {
            this.isOrderPay = !this.isOrderPay
            if (this.orderState === ORDER_SPLIT_STATE) {
                this.toggleSplit()
            }
        },
        toggleIsPayCommission() {
            if (!this.isFixedCommission) {
                this.isPayCommission = !this.isPayCommission
            }
        },
        async checkProductByIndex(index: number, selected: number) {
            this.products[index]['selected'] = selected
            if (selected > 0) {
                socket.emit('product-select', {
                    order_id: this.orderId,
                    product_id: this.products[index]['id'],
                    amount: this.products[index]['availableAmount'],
                    selected: this.products[index]['selected']
                })
            } else {
                socket.emit('product-unselect', {
                    order_id: this.orderId,
                    product_id: this.products[index]['id'],
                    selected: 0
                })
            }
        },
        async emitUserToPayment(paymentId: string, products: SelectedProduct[], isFullPay: boolean) {
            socket.emit('user.goto.payment', {
                order_id: this.orderId,
                products: products,
                payment_id: paymentId,
                is_full_pay: isFullPay
            })
        },
        async getTipsUrl() {
            if (this.orderId) {
                return await tipsService.getUrlByOrder(this.orderId)
            } else {
                if (this.tableSlug === null) {
                    throw new LogicError('tableSlug not set')
                }
                return await tipsService.getUrlByTable(this.tableSlug)
            }
        },
        setPaymentType(type: PaymentType) {
            this.paymentType = type
            storage.set('payment_type', type)
        },
        storeUserEmail() {
            storage.set('user_email', this.userEmail)
        },
        async checkPayment(paymentId: string) {
            let paymentData: Payment

            try {
                paymentData = await orderService.getPayment(paymentId)

                setTimeout(async () => {
                    if (!this.rest['id']) {
                        const rest = await restService.getRestBySlug(paymentData['restaurant']['slug'])
                        this.setRest(rest)
                    }
                }, 0)

                if (!paymentData['paid_at'] && !paymentData['error_at']) {
                    this.checkPaymentTimer = setTimeout(() => {
                        this.checkPayment(paymentId)
                    }, 5000)
                } else {
                    clearTimeout(this.checkPaymentTimer)
                    if (paymentData['order']) {
                        await this.loadOrderById(paymentData['order']['id'])
                    } else {
                        this.setRest(paymentData['restaurant'])
                    }
                }
            } catch (error) {
                if (error instanceof AxiosError && error.response && error.response.status === 404) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore TODO paymentData не приведе к типу Payment
                    paymentData = {
                        'error_at': (new Date()).toISOString(),
                        'error_message': 'Оплата не найдена'
                    }
                } else {
                    throw error
                }
            }

            this.payment = paymentData
        },

    },
})
